/*
Any CSS added to this file or imported from this file, e.g. `@import '../stylesheets/my-css.css'`,
will be included in the "application" pack. Any CSS imported from application.js or as part of the
application.js dependency graph, e.g. `import '../stylesheets/my-css.css'` will also be included
in the "application" pack.

To reference this file, add <%= stylesheet_pack_tag 'application' %> to the appropriate
layout file, like app/views/layouts/application.html.erb
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] {
  display: none !important;
}

@layer base {
  .a {
    @apply text-va-blue underline hover:text-va-blue-700 visited:text-neutral-500;
  }

  .h1 {
    @apply text-3xl font-bold;
  }

  .h2 {
    @apply text-2xl font-bold;
  }

  .h3 {
    @apply text-xl font-bold;
  }

  .h4 {
    @apply text-lg font-bold;
  }

  .prose a {
    @apply text-va-blue underline hover:text-va-blue-700 visited:text-neutral-500;
  }
  .prose blockquote {
    @apply italic text-xl text-neutral-600;
  }
  .prose h1 {
    @apply text-3xl font-bold;
  }
  .prose h2 {
    @apply text-2xl font-bold mt-4 mb-1;
  }
  .prose h3 {
    @apply text-xl font-bold mt-4 mb-1;
  }
  .prose h4 {
    @apply text-lg font-bold mt-4 mb-1;
  }
  .prose p {
    @apply mb-4;
  }
  .prose ul {
    @apply list-disc ml-4 mb-2;
  }
  .prose ol {
    @apply list-decimal ml-4 mb-2;
  }
  .prose ul li,
  .prose ol li {
    @apply mb-1;
  }

  #beacon-container {
    @apply print:hidden;
  }
}

@layer components {
  @responsive {
    .text-shadow {
      text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
    }

    .text-shadow-none {
      text-shadow: none;
    }

    .text-halo {
      text-shadow:
        -1px -1px 1px #fff,
        1px -1px 1px #fff,
        -1px 1px 1px #fff,
        1px 1px 1px #fff;
    }
  }
}

/* Because the reset overrides this */
.mapboxgl-ctrl-attrib-button,
.maplibregl-ctrl-attrib-button {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd'%3E%3Cpath d='M4 10a6 6 0 1012 0 6 6 0 10-12 0m5-3a1 1 0 102 0 1 1 0 10-2 0m0 3a1 1 0 112 0v3a1 1 0 11-2 0'/%3E%3C/svg%3E") !important;
  background-color: hsla(0, 0%, 100%, 0.5) !important;
}
